/**
 *
 * SocialButtons
 *
 */

import React, { useState } from 'react';
import redes from '../../../config/redes';
import urls from '../../../config/strapi';
import Button from 'react-bootstrap/Button';
import { Col } from 'react-bootstrap';


let ShareButtons = (props) => {

  const [active, setActive] = useState(props.active)
  const {esblog, link, type, variant} = props;

  const openShare = () =>{
    setActive(!active);
  }

  const renderShareButtons = () =>{
    let url = esblog ? `${encodeURI(urls.URL_SHARE)}${link}`: link;
    return(
      <Col className={`share-buttons ${active ? "active" : ''}`}>
        <a title="compartir en facebook" aria-label="compartir en facebook" rel="noreferrer" href={`https://facebook.com/sharer/sharer.php?u=${url}`} target="_blank" className="share-button"><i className="im im-facebook"></i><span className="d-none">Compartir en Facebook</span>  </a>
        <a title="compartir en twitter" aria-label="compartir en twitter" rel="noreferrer" href={`https://twitter.com/intent/tweet/?text=&url=${url}`} target="_blank" className="share-button"><i className="im im-twitter"></i><span className="d-none">Compartir en Twitter</span></a>
        <a title="compartir en whatsapp" aria-label="compartir en whatsapp" rel="noreferrer" href={`https://wa.me/?text=${url}`} target="_blank" className="share-button"><i className="im im-whatsapp"></i><span className="d-none">Compartir en Whatsapp</span></a>
        {/* <a href={`fb-messenger://share/?link=${url}&app_id=2039323929539855`} target="_blank" className="share-button"><i className="im im-facebook-messenger"></i></a> */}
        <a title="compartir por mail" aria-label="compartir por mail" href={`mailto:?body=${url}`} target="_blank" rel="noreferrer" className="share-button"><i className="im im-mail"></i><span className="d-none">Compartir por mail</span></a>
      </Col>

    )
  }
  
  switch(type){
    case "share":
      return renderShareButtons()
    case "collapsed":
      return(
        <div className="social-container">
          <Button 
            aria-label='Abrir Compartir'
            onClick={openShare} variant={`${variant === "inverted" ? "other inverted" : "other"}`} size="small" className={`open-share ${active ? "active" : ''}`}>
            <i className="im im-share"></i>
            <span className="d-none">Abrir Compartir</span>
          </Button>
          <div className="visible-md-down mobile">
            {renderShareButtons()}
          </div>
        </div>
        )
    default:
      return(
        <div className="redes">
          <a title="Facebook" aria-label="Facebook" rel="noreferrer" href={redes.facebook} target="_blank" className="nav-link link-redes"><i className="im im-facebook"></i><span className="d-none">Facebook</span></a>
          <a title="Twitter" aria-label="Twitter" rel="noreferrer" href={redes.twitter} target="_blank" className="nav-link link-redes"><i className="im im-twitter"></i><span className="d-none">twitter</span></a>
          <a title="Instagram" aria-label="Instagram" rel="noreferrer" href={redes.instagram} target="_blank" className="nav-link link-redes"><i className="im im-instagram"></i><span className="d-none">Instagram</span></a>
          <a title="YouTube" aria-label="YouTube" rel="noreferrer" href={redes.youtube} target="_blank" className="nav-link link-redes"><i className="im im-youtube"></i><span className="d-none">YouTube</span></a>
          <a title="Vimeo" aria-label="Vimeo" rel="noreferrer" href={redes.vimeo} target="_blank" className="nav-link link-redes"><i className="im im-vimeo"></i><span className="d-none">Vimeo</span></a>
          <a title="SoundCloud" aria-label="SoundCloud" rel="noreferrer" href={redes.soundcloud} target="_blank" className="nav-link link-redes"><i className="im im-soundcloud"></i><span className="d-none">SoundCloud</span></a>
          <a title="Mail" aria-label="Mail" href={redes.mail} target="_blank" rel="noreferrer" className="nav-link"><i className="im im-mail"></i><span className="d-none">Mail</span></a>
          {/* { auth.getUserInfo() != null
            ? <LinkR to="/auth/login" className="nav-link" onClick={() => {auth.clearAppStorage();}}><i className="im im-sign-out"></i></LinkR>
            : <LinkR to="/auth/login" className="nav-link" ><i className="im im-sign-in"></i></LinkR>
          } */}
        </div>
      )
  }
     
}

export default ShareButtons

