/**
 *
 * TableRow
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { isEmpty } from 'lodash';


const TableRow = (props) => {
  let cols = 12/props.headers.length;
  let type = props.contentType;
  let id = props.data.id;

  switch(type){
    case "notas":
    id = props.data.id_url;
    break;
    case "ediciones":
    id = props.data.numero;
    break;
    case "cocreadores":
    id = props.data.username;
    break
    default:
    id = props.data.id
  }

  return (
    <div className="row row-item"
    >
      {props.headers.map((header, key) => {
        cols =  header !== 'titulo' && header !== 'portada' && header !== 'hashtag' ? 12/props.headers.length/2: 12/props.headers.length;
        cols = header === 'titulo' ? parseInt(cols * 2, 10 ): parseInt(cols, 10)

        if (header === 'portada' && !isEmpty(props.data[header])) {
          //const picture = isArray(props.data[header]) ? get(props.data, [header, '0', 'url'], '') : get(props.data, ['header', 'url'], '');
          const src = props.data[header].url;

          return (
            <div key={key} className={`col-md-${cols}`}>
              <img src={src} alt={props.data[header].name} />
            </div>
          );
        }

        if (header === 'imagen' && !isEmpty(props.data[header])) {
          //const picture = isArray(props.data[header]) ? get(props.data, [header, '0', 'url'], '') : get(props.data, ['header', 'url'], '');
          const src = props.data[header].url;

          return (
            <div key={key} className={`col-md-2`}>
              <img src={src} alt={props.data[header].name} />
            </div>
          );
        }

        // Prepare for actions
        if (header === '') {
          return (
            <div key={key} className={`col-md-${cols}`}>
              
            </div>
          );
        }

        if (header === 'edicion') {
          return (
            <div key={key} className={`col-md-${cols}`}>
              {props.data[header] ? props.data[header].numero : "VACIO"}
            </div>
          );
        }
        return (
          <div key={key} className={`col-md-${cols}`}>
            {typeof props.data[header] !== "boolean" ? props.data[header]: props.data[header] ? "SI" : "NO"}
            {}
          </div>
        );
  

      })}
      <div className={`col-md-1`}>
        {props.data.id_url ?
        <a href={ props.data.id_url ? `notas/${props.data.id_url}`: props.data.link}>ver</a>
        : "-"}
      </div>
      <div className={`col-md-1 editar`}  
        onClick={(e) => {
          //console.log(type)
          props.onClick(id);
      }}>Editar</div>
            <div className={`col-md-1 editar`}  
        onClick={(e) => {
          //console.log(type)
          props.onClickAutomatico(props.data);
      }}>Editar AUTOMATICO</div>
    </div>
  );
}

TableRow.defaultProps = {
  data: {},
  headers: [],
  onClick: (e) => {
    e.prevendivefault();
    console.log('click');
  },
};

TableRow.propTypes = {
  data: PropTypes.object,
  headers: PropTypes.array,
  onClick: PropTypes.func,
};
export default TableRow;
