/* eslint-disable no-useless-escape */
const htmlClasslist = document.querySelector("html").classList;

export const setColor = (color, htmlOnly) => {
  if(!htmlOnly) if(window.localStorage) window.localStorage.setItem('mode', color)

  if(color === 'is-light'){
    htmlClasslist.add(color);
    htmlClasslist.remove("is-dark");

  }else{
    if( color !== null) htmlClasslist.add(color);
    htmlClasslist.remove("is-light");
  }
}

export const getColor = () => {
  if(window.localStorage) {
    return window.localStorage.getItem('mode')
  } else{
    return "is-dark"
  }
}

const changeColor = (darkLight, click) =>{
    if(!htmlClasslist.contains("is-light")) {
        htmlClasslist.remove("is-dark");
        darkLight.classList.add("is-light");
        darkLight.classList.remove("is-dark");
        if(click) setColor('is-light')
      }else{
        htmlClasslist.add("is-dark");
        htmlClasslist.remove("is-light");
        darkLight.classList.add("is-dark");
        darkLight.classList.remove("is-light");
        if(click) setColor('is-dark')
      }
  }

export const toggleColor = (e) => {
    const eventType = e.type === "mouseenter" ? "hover" : e.type === "mouseleave" ? "leave": "click";
    const darkLight = document.querySelector(".change-color")
    const time = 2000;
    if(eventType === "hover"){
      if(!darkLight.classList.contains("hover")){
        darkLight.classList.add("hover");
        changeColor(darkLight);
        setTimeout(() => {
          if(darkLight.classList.contains("hover")){
            changeColor(darkLight);
          }
        }, time);
      }

    }else if(eventType === "click"){
      darkLight.classList.remove("hover")
      darkLight.classList.add("clicked")
      changeColor(darkLight, true);
      return false
    
    }else if(eventType === "leave"){
      darkLight.classList.remove("hover");
      darkLight.classList.remove("clicked");
    }

    //   // const time = 2000;
    //   // if(eventType === "hover"){
    //   //   htmlClasslist.toggle("hover");
    //   //     if(htmlClasslist.contains("hover")) htmlClasslist.toggle("is-light");
    //   //     setTimeout(() => {
    //   //       if(htmlClasslist.contains("hover")) htmlClasslist.toggle("is-light");  
    //   //       setTimeout(() => {
    //   //         if(htmlClasslist.contains("hover")) htmlClasslist.toggle("is-light");  
    //   //         setTimeout(() => {
    //   //           if(htmlClasslist.contains("hover")) htmlClasslist.toggle("is-light");  
    //   //           if(htmlClasslist.contains("hover")) htmlClasslist.toggle("hover");
    //   //         }, time);
    //   //       }, time);
    //   //     }, time);
    //   // }else{
    //   //   setDark(!isDark);
    //   //   htmlClasslist.remove("hover");
    //   //   if(e.type !== "mouseleave") htmlClasslist.toggle("is-light");  
    //   // }
}

//export default toggleColor;