/**
 * Header
 *
 */

import React, {createRef, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Logo from "../../../../assets/Logo";
import TextBox from "../../../elements/TextBox";
// import auth from '../../../utils/auth'; // TODO: Add Auth

import SocialButtons from "../../../wrappers/SocialButtons"
import { Button, Nav, Col, Row} from 'react-bootstrap';

import Link from "../../../elements/Link"

import { store } from '../../../../core/store/store';

import { toggleColor, getColor, setColor } from '../../../../utils/logoAnimation';
import { returnLinks } from '../../helpers/helpers'


let Header = (props) => {
  const {type, isEdit, titulo, item, onChange, isDark} = props;
  const [loaded, setLoaded] = useState(false)
  const myRef = createRef();
  const history = useHistory();
  const [openMenu,setOpenMenu]= useState(false);
  const [showing, setShowing] = useState(true);

  const globalState = useContext(store);
  const { header } = globalState.state; 

  setColor(getColor(), true);

  useEffect(()=> {
    if(!loaded){
      if(type === "full") {
        setLoaded(header.length !== 0 );
      }else{
        setLoaded(true);
      }
    }
  },[header, loaded, type])

  useEffect(() => {
    const handler = event => {      
      if(event.data === 'started'){
        setShowing(false)
      }
    }

    window.addEventListener("message", handler)

    // clean up
    return () => window.removeEventListener("message", handler)
  }, []) // empty array => run only once




  if(!loaded){
    return false;
  }

  const renderLink = (dir, key) =>{
    return(
      <Nav.Item key={key}>
        <Link className="nav-link link-header" link={dir.url}>{dir.texto}</Link>
      </Nav.Item>
    )
  }

  const renderLinks = () => {
    if(type === "full") {
      return (
      <React.Fragment>
        {returnLinks(header, renderLink)}
        <SocialButtons />
      </React.Fragment>)
    } else {
      return (
        isEdit ?         
          <Col lg={{ span: 10, offset: 1}} xs={6}>
            <TextBox  onChange={onChange} isEdit={isEdit} content={titulo} editName={item} type="text"/>
          </Col>
          : 
          <Col  lg={{ span: 10, offset: 1}} xs={6} as="h1">{titulo}</Col>
      )
    }
  }

  const renderLogo = () => {
    if(type === "full"){
      return ( <span className={``} ><span className="logo" onClick={e => history.push("/")}><Logo /></span></span> );
     }else{
      return (<Link className="" link={"/"} type="back"><i className="im im-angle-left"></i></Link>)
    }
  }

  const renderMenuToggle = () => {
    return (
      <div className="toggle" onClick={e=>setOpenMenu(!openMenu)}>
        <i className={`im im-${openMenu ? "minus" : "menu"}`}></i>
      </div>
    )
  }

  const renderColorToggle = () => {
    return(
      <div className="change-color"
        onClick={e=>toggleColor(e)}
        onMouseEnter={e=>toggleColor(e)}
        onMouseLeave={e=>toggleColor(e)}
      >
      
      <Button
        aria-label='portal magma'
        className={`dark-light ${isDark? "light" : "dark"}`}
        style={{margin:'5px'}}
        variant="other">
        {type === "full" && <Col as="span" className="logo2"><span className="d-none">Portal Magma</span></Col>}
      </Button>
    </div>
    )
  }

    return (
      <header 
        className={`header ${type !== 'full' ? "header-simple" :"container-fluid new-header"} ${!showing ? 'header-reduced' : ''}`} 
        ref={myRef}>

        {type === "full" && renderMenuToggle() }
      
        <Row className={`${openMenu && 'opened'} header-links` }>
          {renderLinks()}
        </Row>

        <Row className={`logos ${showing ? 'logos-show' : 'logos-hide'}`}>
          {renderLogo()}
          {renderColorToggle()}
        </Row>
    </header>
    )
}

export default Header;
