/**
 * TextBox
 *
 */

import React from 'react';
import Input from '../../wrappers/Form/InputsIndex';
import Markdown from 'markdown-to-jsx';
import Dialogflow from '../DialogFlow';

let TextBox = (props) => {

// export default class TextBox extends React.Component {
    // state = {
    //     didCheckErrors: false,
    //     errors: [],
    //     // inititalData: {},
    //     // modifiedData: {},
    //   };
  
    // render() {
    
  const {forceBlock, forceInline, content, isEdit, editName, onChange, type, isSimple, markdownClass} = props
  const MyH1 = ({children, ...props}) => (<p {...props}>{children}</p>);
  const Dialg = ({children, ...props}) => (<div className="dialogflow"><Dialogflow {...props}>{children}</Dialogflow></div>);
  let options= {overrides: {p:MyH1, dialogflow: Dialg}}

  options=  forceBlock ? {forceBlock:true, overrides: {p:MyH1, dialogflow: Dialg}}:options
  options = forceInline ? {forceInline:true, overrides: {p:MyH1, dialogflow: Dialg}}:options
  let value = content === "undefined" ? "" : content;

  if (isEdit){
      return (
      <div className="input-container">
          <Input
          didCheckErrors={false}
          errors={[]}
          name={editName}
          onChange={onChange}
          type={type ? type : "textarea"}
          value={value}
          label={editName}
          customBootstrapClass={"col-md-12"}
          isSimple={isSimple}
        />
      </div>
      )
      
  }else{
      return <Markdown className={`markdown ${markdownClass ? markdownClass : ''}`}options={options}>{value}</Markdown>
  }
}

export default TextBox