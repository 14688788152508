/**
 * Card
 *
 */

import React, { useEffect } from 'react';

let Dialogflow = (props) => {

  // const [active, setActive] = useState(false);
  const {intent, chatTitle, agentId, languageCode} = props;
  const body = document.body;
  // const elScript = document.querySelector("#dialog-script");
  // const arrayOfScripts = Array.from(document.querySelectorAll('script'))
  // const filteredScripts = arrayOfScripts.filter(s => s.src === 'https://www.gstatic.com/dialogflow-console/fast/messenger/messenger-internal.min.js?v=4')


  useEffect(() => {
      const script = document.createElement('script');
      script.id = "dialog-script";
      script.src = "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
      script.async = true;
      body.appendChild(script);
  

      const dfMessenger = document.querySelector('df-messenger');
          dfMessenger.addEventListener('df-button-clicked', function (event) {
            // Handle event
            alert("LLALALAL");
          });


      window.addEventListener('dfMessengerLoaded', function (event) {
        const $r1 = document.querySelector("df-messenger");      
        const $r2 = $r1.shadowRoot.querySelector("df-messenger-chat");
  
        const theStyle = $r2.shadowRoot.querySelector("style");
        theStyle.innerHTML = `div.chat-wrapper[opened="true"] { height: 400px; max-height: 50%;} ${theStyle.innerHTML}`
      
      });

  
      return () => {
        document.body.removeChild(script);
    }
    
    
  }, [body]);

  return (
  <React.Fragment>
    <df-messenger
      intent={intent}
      chat-title={chatTitle}
      agent-id={agentId}
      language-code={languageCode}
      chat-icon="https://www.proyectomagma.org/assets/img/logo-proyecto-magma.svg"
    ></df-messenger>
</React.Fragment>


  );
}

export default Dialogflow;
