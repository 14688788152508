/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash';
import cn from 'classnames';

// Design
import Label from '../Label';
import InputDescription from '../InputDescription';
import InputErrors from '../InputErrors';
import TextArea from '../textArea';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Utils
import validateInput from '../../../../utils/inputsValidations';


const TextAreaWithErrors = (props) => {

  const [stateerrors, setErrors] = useState([]);
  const [hasInitialValue, setHasinitialValue] = useState(false);
  const {  errors } = props;
  const editor = useRef(null)
	const [content, setContent] = useState(props.value)
	
	const config = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}

  const {
    autoFocus,
    className,
    customBootstrapClass,
    deactivateErrorHighlight,
    disabled,
    errorsClassName,
    errorsStyle,
    inputClassName,
    inputDescription,
    inputDescriptionClassName,
    inputDescriptionStyle,
    inputStyle,
    label,
    labelClassName,
    labelStyle,
    name,
    noErrorsDescription,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    style,
    tabIndex,
    value,
    isSimple,
  } = props;
  // Prevent the input from displaying an error when the user enters and leaves without filling it

  // if (!isEmpty(value)) {
  //     setHasinitialValue(true);
  // }

  // // Display input error if it already has some
  // if (!isEmpty(errors)) {
  //   setErrors(errors);
  // }
 
  useEffect(() => {
    // if (!isEmpty(props.value) && !hasInitialValue) {
    //   setHasinitialValue(true);
    // }
    // Check if errors have been updated during validations
    // if (nextProps.didCheckErrors !== this.props.didCheckErrors) {
    //   // Remove from the state the errors that have already been set
    //   const errors = isEmpty(errors) ? [] : nextProps.errors;
    //   setErrors(errors);
    // }
  
  }, [props])

  // componentWillReceiveProps(nextProps) {
  //   // Show required error if the input's value is received after the compo is mounted
   
  // }

  /**
   * Set the errors depending on the validations given to the input
   * @param  {Object} target
   */
  const handleBlur = ({ target }) => {
    // Prevent from displaying error if the input is initially isEmpty
    //if (!isEmpty(target.value) || this.state.hasInitialValue) {
      const errors = validateInput(target.value, props.validations);
      setErrors(errors);
      setHasinitialValue(true)
    //}
  };

  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
  }

   
    const handleBlur2 = isFunction(onBlur) ? onBlur : handleBlur;

    let spacer = !isEmpty(inputDescription) ? (
      <div className="spacer" />
    ) : (
      <div />
    );

    if (!noErrorsDescription && !isEmpty(stateerrors)) {
      spacer = <div />;
    }

    const lStyle = !isEmpty(labelStyle) ? labelStyle : { marginTop: '3px' };

    return (
      <div
        className={cn(
          'inputTextWithErrors',
          customBootstrapClass,
          !isEmpty(className) && className
        )}
        style={style}
      >
        <Label
          className={labelClassName}
          htmlFor={name}
          message={label}
          style={lStyle}
        />

        {isSimple?
              <TextArea
              autoFocus={autoFocus}
              className={inputClassName}
              disabled={disabled}
              deactivateErrorHighlight={deactivateErrorHighlight}
              error={!isEmpty(stateerrors)}
              name={name}
              onBlur={handleBlur2}
              onChange={onChange}
              onFocus={onFocus}
              placeholder={placeholder}
              style={inputStyle}
              tabIndex={tabIndex}
              value={value}
            />
          :

            <TextArea
            autoFocus={autoFocus}
            className={inputClassName}
            disabled={disabled}
            deactivateErrorHighlight={deactivateErrorHighlight}
            error={!isEmpty(stateerrors)}
            name={name}
            onBlur={handleBlur2}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder}
            style={inputStyle}
            tabIndex={tabIndex}
            value={value}
          />
          // <CKEditor
          //           editor={ ClassicEditor }
          //           data={value}
          //           onInit={ editor => {
          //               // You can store the "editor" and use when it is needed.
          //              // console.log( 'Editor is ready to use!', editor );
          //           } }
          //           onChange={ ( event, editor ) => {
          //               const data = editor.getData();
          //               //console.log( { event, editor, data } );
          //               onChange(name, data, true);
          //           } }
          //           onBlur={ ( event, editor ) => {
          //               //console.log( 'Blur.', editor );
          //           } }
          //           onFocus={ ( event, editor ) => {
          //               //console.log( 'Focus.', editor );
          //           } }
          //       />
          }
          

                

        

        <InputDescription
          className={inputDescriptionClassName}
          message={inputDescription}
          style={inputDescriptionStyle}
        />
        <InputErrors
          className={errorsClassName}
          errors={(!noErrorsDescription && stateerrors) || []}
          style={errorsStyle}
        />
        {spacer}
      </div>
    );
}

TextAreaWithErrors.defaultProps = {
  autoFocus: false,
  className: '',
  customBootstrapClass: 'col-md-6',
  deactivateErrorHighlight: false,
  didCheckErrors: false,
  disabled: false,
  onBlur: false,
  onFocus: () => {},
  errors: [],
  errorsClassName: '',
  errorsStyle: {},
  inputClassName: '',
  inputDescription: '',
  inputDescriptionClassName: '',
  inputDescriptionStyle: {},
  inputStyle: {},
  label: '',
  labelClassName: '',
  labelStyle: {},
  noErrorsDescription: false,
  placeholder: '',
  style: {},
  tabIndex: '0',
  validations: {},
  onChange:() => {console.log()}
};

TextAreaWithErrors.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  customBootstrapClass: PropTypes.string,
  deactivateErrorHighlight: PropTypes.bool,
  didCheckErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  errorsClassName: PropTypes.string,
  errorsStyle: PropTypes.object,
  inputClassName: PropTypes.string,
  inputDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({
      id: PropTypes.string,
      params: PropTypes.object,
    }),
  ]),
  inputDescriptionClassName: PropTypes.string,
  inputDescriptionStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({
      id: PropTypes.string,
      params: PropTypes.object,
    }),
  ]),
  labelClassName: PropTypes.string,
  labelStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  noErrorsDescription: PropTypes.bool,
  onBlur: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  tabIndex: PropTypes.string,
  validations: PropTypes.object,
  value: PropTypes.string.isRequired,
};

export default TextAreaWithErrors;
