import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

let SearchBar = (props) => {
  const {handleAgregar, courses, listType} = props;
  const [search, setSearch] = useState([])
  let options;

  const clearSearch = (e) =>{
    e.target.value="";
    setSearch('')
  } 

  let nombre = "nombre"
  switch(listType){
    case "notas":
    nombre = "titulo"
    break
    case "hashtags":
    nombre = "titulo"
    break
    case "edicion":
    nombre = "titulo"
    break
    case "secciones":
    nombre = "titulo"
    break
    default:
    nombre = "titulo"
  }

  if (search.length) {
    const searchPattern = new RegExp(search.map(
    (term) =>{
        if (term.length> 0){
            return  `(?=.*${term})`
        }else{
            return  `(?=.*xxxxxxxxx)`
        }
    }).join(''), 'i');

    options = courses.filter(option => {
      if(option[nombre]){
        return option[nombre].match(searchPattern)
      }
        return false
    });
  } else {
    options = [];
  }

  return (
    <React.Fragment>
      <input type="text" onChange={(e) => setSearch(e.target.value.split(' '))}/>
      <ul>
          {options.map((option, i) => 
            <li className="related-list-item" key={i}  >
            <p>{`${option[nombre]} ${option.edicion ? "- [edicion: " + option.edicion.numero + "]": ""} `}</p>
            <Button type="button" variant="primary" onClick={(e) => {handleAgregar(listType, option); clearSearch(e)}}></Button>
            </li>
          )}
      </ul>
    </React.Fragment>
  )
}

export default SearchBar


