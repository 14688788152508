/**
 *
 * TableHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({ headers }) => {
  let cols;
  return (
      <div className="row row-header">
        {headers.map((header, key) => {
          cols =  header !== 'titulo' && header !== 'portada' && header !== 'hashtag' ? 12/headers.length/2: 12/headers.length
          cols = header === 'titulo' ? parseInt(cols,10) * 2 : parseInt(cols,10)
          cols = header === 'imagen' ? 2 : cols;
          return(
          <div key={key} className={`col-md-${cols}`}>
            <span>
              {header.replace(/es/, '')}
            </span>
          </div>
          )
        }
        )}
      </div>
  );
}

TableHeader.defaultProps = {
  headers: [],
};

TableHeader.propTypes = {
  headers: PropTypes.array,
};

export default TableHeader;
