/**
 *
 * Lista Elements
 *
 */

import React, { useState, useEffect, Suspense, useMemo} from 'react';
// import { connect, useSelector } from "react-redux";

import SearchBar from "../../elements/SearchBar"
import {Button, DropdownButton} from 'react-bootstrap';

// NAVIGATION
import Loader from '../../navigation/components/Loader';
import Link from "../../elements/Link"
import { isMobile } from 'react-device-detect';

const Carousel = React.lazy(() => import("../../wrappers/Carousel"));
const Nota = React.lazy(() => import("../../sections/Nota"));
const Cocreador = React.lazy(() => import("../../sections/Cocreador"));
const Edicion = React.lazy(() => import("../../sections/Edicion"));
const Categoria = React.lazy(() => import("../../sections/Categoria"));
const Hashtag = React.lazy(() => import("../../sections/Hashtag"));
const Seccion = React.lazy(() => import("../../sections/Seccion"));


let ListElements = (props) => {
  //const store = useSelector(store => store);

  const [limit, setLimit] = useState(props.limit)
  const {relacion, conImagen, destacada, isEdit, active, carousel, lista,
    isFilter, element, autoPlay, autoPlaySpeed, carouselNumbers, hasAll, noBackground, 
    titulo, handleToUpdate, noClick, type, eleStyle, speed, onChangeList, onlyCat, cols, id, hideShare} = props;
  const isSimple = type === "lista-simple" ? true: false;
  // const { notas, cocreadores, ediciones, hashtags, secciones, categorias } = [store.data];
  const { notas, cocreadores, ediciones, hashtags, secciones, categorias } = [];
  const containerStyle = noBackground ? {background:'none' }:{};
  const containerClass = `${!isEdit ?  element+"-container": ""} ${hasAll ? "col-md-12 d-flex d-flex flex-column justify-content-end align-items-start": ""}`;
  const wrapperClass = `${eleStyle ? eleStyle : "" } ${!relacion ?  element+"-wrapper" : "row"} `;
  const wrapperStyle = speed ? {animationDuration: + props.speed + 's'}:{}
  const title = isEdit ? <h3 className="titulo">{element}</h3>: lista && titulo && lista.length > 0 ? <h3 className="titulo">{titulo}</h3> : "";

  const returnElementName = useMemo(() => (isData) => {  
    const tipo = element;
    let name = tipo;

    if (tipo === "notas"){
      switch(relacion){
        case "cocreadores":
        name = "notas_cocreadores"
        break
        case "edicion":
        name = "notas_edicion";
        break
        case "hashtag":
        name = "notas";
        break
        case "seccion":
        name = "notas_seccion";
        break
        default:
        name = relacion
      }
    }else if (tipo === "secciones"){
//      name = "secciones"
      name = isData? "secciones": "seccion";
    }else if(tipo === "autores"){
      name = isData? "cocreadores": "autores";
    }else if(tipo === "edicion"){
      name = isData? "ediciones": name;
    }
    return name;
  },[element, relacion])


  const returnElementName2 = (isData) => {
    const tipo = element;
    let name = tipo;

    if (tipo === "notas"){
      switch(relacion){
        case "cocreadores":
        name = "notas_cocreadores"
        break
        case "edicion":
        name = "notas_edicion";
        break
        case "hashtag":
        name = "notas";
        break
        case "seccion":
        name = "notas_seccion";
        break
        default:
        name = relacion
      }
    }else if (tipo === "secciones"){
//      name = "secciones"
      name = isData? "secciones": "seccion";
    }else if(tipo === "autores"){
      name = isData? "cocreadores": "autores";
    }else if(tipo === "edicion"){
      name = isData? "ediciones": name;
    }
    return name;
  } 
  useEffect(() => {
    // let element = returnElementName(true);
    if(notas && cocreadores && ediciones && hashtags && secciones && categorias){
     // settheElement(store.data[element])
    }
  }, [ props.lista, notas, cocreadores, ediciones, hashtags, secciones, categorias, returnElementName])


  // let otherElement = returnElementName(true);
  // const [theelement, settheElement] = useState(store.data[otherElement]);
  const [theelement] = useState([]);

  const returnValor = (i) => {  
    let valor; //= i.nombre || i.attributes.titulo;    
    
    switch(element){
      case "hashtags":
      valor = i.attributes.titulo;
      break
      case "secciones":
      valor = i.attributes.titulo;
      break
      case "categorias":
      valor = i.attributes ? i.attributes.titulo : i.titulo;
      break
      default:
      valor = i.nombre || i.titulo;
    }
    return valor
  }

  const loadMore = (length,limit) => {  
    if (props.lista){
      if(length > limit){
        setLimit(limit + props.limit);
      }else{
        setLimit(props.limit);
      }
    }
  }

  const handleBorrar = (listType, id) => {  

    let filter = lista ? lista.filter(item => item.id !== id) : {};
    listType = element === "notas" && relacion === "notas" ? "notas_relacionadas": returnElementName2();
    
    console.log("filter:BORAR::", listType, filter, id)

    onChangeList(listType, filter, "BORRAR", id);
  }

  const handleAgregar = (listType, id) => {  
    let filter = lista;
    listType = element === "notas" && props.relacion === "notas" ? "notas_relacionadas": returnElementName2();
    
    if(listType === "seccion" || listType === "edicion"){
      filter = id;
    }else{
      if (filter.length > 0){
        let existingIds = lista.map((item) => item.id);
        if (!existingIds.includes(id.id)) {
          filter.push(id);
        }   
      }else{
        filter.push(id);
      }
    }

    console.log("filter:::", filter)
    onChangeList(listType, filter, "AGREGAR", id);
    //setLista(filter);
  }
 
  const renderSearch = () => {  
    let listaFull = theelement;
    let listaIds = lista ? lista.map(elem => elem.id) : [];
    let courses = (listaFull) ? listaFull :[];
    let name = returnElementName2();

    return (
    <div className="add-rel-item">
      <input type="hidden" data-rel={name} className="hidden-input" name={name === "secciones" ? "seccion" : name} value={listaIds}></input>
      <SearchBar courses={courses} listType={element} lista={lista} handleAgregar={handleAgregar}></SearchBar>
    </div>
  )
  }

  const renderReset = (listType) => {  
    listType = element === "notas" && props.relacion === "notas" ? "notas_relacionadas": returnElementName();
    let handleReset = props.handleReset;
    const listLength = active ? active.length : lista.length; 
    // console.log("ACTIVE",active);
    
    let contains = listLength > 0 ? true: false;
    
    if(listLength > 0){
      return (
        <Button onClick={() => handleReset(listType)} size="small" variant="secondary" className={`${contains?"":""} borrar align-self-end`}>
          {props.isFilter ? "borrar" : "ver todos"}
        </Button>
      )         
    }    
  }

  const renderLoadMore = () => {  
    const listLength = active ? active.length : lista ? lista.length : 0; 
    
    if(limit && !isEdit && !carousel){
      if(listLength > limit){        
        return(
          <Button type="button" variant="terciary" className="ver-mas" onClick={() =>loadMore(listLength, limit)}>
            <span>
          {lista && listLength > limit ? `ver +${listLength - limit}`:"colapsar"}</span></Button>
        )
      }
      return false
    }
    return false
  }

  const renderSwtich = (i, key) => {  
    const thecols = !relacion ? 6 : relacion === "seccion" ? 4 :4 ;
    const contains = active ? active.includes(i.id): false;
    const valor = returnValor(i); 
    const isCompact = type === 'compact' ? true : false;

    switch(element){
      case "cocreadores":
        if(isSimple){          
          let link, nombre, aporte;

          if(i.user){
            aporte = i.user? `${i.aporte ? ": " + i.aporte :''}` : "";
            link = i.user.data && i.user.data.attributes.username;
            nombre = i.user.data && i.user.data.attributes.nombre;
          }else {
            link = i.username;
            nombre =  i.nombre;
          }

          return (
            <Link link={`/cocreadores/${link}`} key={key}>
              <span>{nombre}</span>
             {aporte}
             {props.animado ? "  • ": "" }
            </Link>
          )
        }else{
          return <Cocreador 
            type={isCompact ? "compact" : "mini"}
            id={i.username} 
            key={key}
            over={false}
            cols={cols ? cols : 12}
            carousel={false}
            data={i}
          />
        }
      case "ediciones":
        if(i.attributes.numero > 0 && Number.isInteger(i.attributes.numero)){
          return (
            <React.Fragment key={key}>
              <Edicion 
                type="mini" 
                id={i.attributes.numero} 
                //cols={parseInt(12/props.carouselNumbers[0],0)}
                carousel={props.carousel}
              />
            </React.Fragment>
            )
        }
      return false
      case "notas":

          const id  = i.attributes.esblog ? i.attributes.id_url : i.id

          return (
            <Nota 
              isEdit={props.isEdit} 
              id={id}
              destacada={destacada}
              conImagen={conImagen}
              type={isCompact ? "compact" : "mini"}
              key={key}
              cols={thecols}
              pageProps={props.pageProps}
              rel={relacion}
              cardType={props.cardType}
              onlyCat={onlyCat}
              hideShare={hideShare}
              data={i}
            />
          )
      case "categorias":
        return (
          <Categoria handleToUpdate={handleToUpdate} content={i} valor={valor} noClick={noClick} contains={contains} key={key} />
        )

      case "hashtags":
        return(
          <Hashtag content={i} valor={valor} key={key} />              
      )
      case "secciones":
        return(               
          <Seccion handleToUpdate={handleToUpdate} content={i} valor={valor} noClick={noClick} contains={contains} key={key} />
        )

      case "all":
      const esCocreador = !!i.username;
      // console.log(i);
      // console.log(esCocreador)

      if(esCocreador){
        return(
          <Cocreador 
            type={isCompact ? "compact" : "mini"}
            id={i.username}
            key={key}
            over={false}
            cols={cols ? cols : 12}
            carousel={false}
            data={i}
          />
        )
      }else{
        return(
            <Nota 
            isEdit={props.isEdit} 
            id={i.attributes.id_url}
            destacada={destacada}
            conImagen={conImagen}
            type={isCompact ? "compact" : "mini"}
            key={key}
            cols={cols ? cols : 12}
            pageProps={props.pageProps}
            rel={relacion}
            cardType={props.cardType}
            onlyCat={onlyCat}
            hideShare={hideShare}
          />
        )
      }          
      default:
        return false
    }
  
  }

  
  const renderLista = () => {  
    let filt = lista;
    //console.log("LISTA", lista);

    if(filt && filt.length > 0) {
      if(limit && !carousel){
        let filteredFilter = filt.slice(0, limit).map((item) => { return item});
        if(!isEdit){
          filt = filteredFilter;
        }
      }
      if(carousel){
        return (
          filt.map((i, key) => { 
            return renderSwtich(i, key);
          })
        )
      }else{

        //console.log("FILTRADA", filt);
        const Wrap = ({condition, title, children}) => {
          if(condition){
            return (
              <DropdownButton id="dropdown-basic-button" title={title}>
                {children}
              </DropdownButton>            
            )
            }else{
              return children;
            }
        }

        const lalista = 
          filt.map((i, key) => { 
            let laEdicion;
            const valor = returnValor(i);
  
            if (i.attributes && i.attributes.edicion && ediciones){
              const itemEdicion = i.attributes.edicion.data.id ? i.attributes.edicion.data.id : i.attributes.edicion.data.edicion;
              laEdicion = ediciones.filter(x => x.id === itemEdicion).map(x => x.numero)
            }
                                
            if(isEdit){
              const contains = active ? active.includes(i.id): false;
              return (
                <div className={`related-list-item ${contains ? "active" : ""}`}key={key} 
                onClick={()=>{handleBorrar(element, i.id)}}>
                    <Button type="button" 
                    variant="bullet"
                    active={contains ?true : false}
                    ></Button>
                    <p key={`item-${key}`}>{`${valor} ${laEdicion ? " - [edicion: " + laEdicion + "]": ""}`}</p>
                </div>
              )
            }else{
              return renderSwtich(i, key);
            }
          })
      

        return (
        <div className={`${wrapperClass}`} style={wrapperStyle}>

            {isEdit && !isMobile ? <span className="separator"></span> :""}

            {isEdit && isMobile  ?
            <Wrap condition={false} title={element}>
              {lalista}
            </Wrap>
            :""}

            <Wrap condition={isEdit && isMobile} title={element}>
              {lalista}
            </Wrap>
          
        </div>
        )
      }       
    }else{
      if(isEdit){
        if(isFilter){
          return (<p>No hay filtro seleccionado</p>)
        }else{
          return (<p>No se encontraron resultados</p>)
        }
      }
      return false
    }


  }

  return (        
      <div 
        {...(id && { id: id })}
      className={containerClass} style={containerStyle}>          

          {title}
          {carousel ?
              <Carousel randomKey={new Date().getTime()} 
              autoPlaySpeed={autoPlaySpeed} 
              autoPlay={autoPlay} 
              items={renderLista()} 
              update={false} 
              numbers={carouselNumbers}
              onlyCat={onlyCat}
              />
          :
            renderLista()
          }
        <Suspense fallback={<Loader></Loader>}>

          {renderLoadMore()}
          {hasAll ? renderReset(element): ""}
          {isEdit && !isFilter? renderSearch() :''}
      </Suspense>
    </div>
  );

}

export default ListElements;
